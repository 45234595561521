import * as actionTypes from "../constants";

const initialState = {
  loading: false,
  project: {},
  updatedProject: {},
  project_id: "",
  tool_category_id: "",
  edit: false,
  currentStep: 1,
  stepVisited: 1,
  step1: {
    id: 1,
    brief: "",
    type: "",
    output: {
      titles: [],
    },
  },
  step2: {
    id: 2,
    brief: "",
    type: "",
    loader: false,
    output: {
      outlines: [],
    },
  },
  step3: {
    id: 3,
    brief: "",
    type: "",
    output: {
      outlines: [],
    },
  },
  step4: {
    id: 4,
    brief: "",
  },
  intro: [],
  paragraph: [],
};

const blogReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.CREATING_BLOG:
    case actionTypes.CREATING_OUTLINE_FROM_TITLE:
    case actionTypes.CREATING_INTRO_FROM_OUTLINE:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.CREATING_INTRO_FROM_OUTLINE_WITH_CUSTOM:
      let oldOutlines = state.step2.output.outlines;
      let latestOutlines = oldOutlines.concat(payload);
      return {
        ...state,
        step2: {
          ...state.step2,
          output: {
            outlines: latestOutlines,
          },
        },
      };

    case actionTypes.DELETE_INTRO_FROM_OUTLINE_WITH_CUSTOM:
      return {
        ...state,
        loading: false,
        step2: {
          ...state.step2,
          output: {
            outlines: payload,
          },
        },
      };

    case actionTypes.CREATING_PARAGRAPH_FROM_INTRO:
      return {
        ...state,
        loading: true,
        loader: true,
        paragraph: payload.paragraph,
        step2: payload,
      };

    case actionTypes.CREATE_BLOG_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        step1: {
          brief: payload?.brief,
          type: payload?.type,
          target_keywords: payload?.target_keywords,
          related_keywords : payload?.related_keywords,
          output: payload?.output,
        },
      };

    case actionTypes.CREATE_BLOG_POST_FAIL:
    case actionTypes.CREATE_OUTLINE_FROM_TITLE_FAIL:
    case actionTypes.CREATE_INTRO_FROM_OUTLINE_FAIL:
    case actionTypes.CREATE_PARAGRAPH_FROM_INTRO_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.CREATE_OUTLINE_FROM_TITLE_SUCCESS:
      return {
        ...state,
        loading: false,
        step2: {
          brief: payload.brief,
          type: payload.type,
          output: payload.output,
        },
      };

    case actionTypes.CREATE_INTRO_FROM_OUTLINE_SUCCESS:
      return {
        ...state,
        loading: false,
        intro: payload,
      };

    case actionTypes.GET_INTRO_SUCCESS:
      return {
        ...state,
        loading: false,
        step4: {
          brief: payload.brief,
        },
      };

    case actionTypes.CREATE_PARAGRAPH_FROM_INTRO_SUCCESS:
      return {
        ...state,
        loading: false,
        paragraph: payload.paragraph,
        step2: {
          ...state.step2,
          loader: false,
          output: {
            outlines: payload.outlines,
          },
        },
        step4: {
          brief: payload.paragraph,
        },
      };

    case actionTypes.UPDATE_OUTLINE_PARAGRAPH:
      const outlines = payload.outlines;
      outlines[payload.position].paragraph = payload.paragraph;
      return {
        ...state,
        step2: {
          ...state.step2,
          output: {
            outlines: outlines,
          },
        },
      };

    case actionTypes.UPDATE_INTRO_PARAGRAPH:
      return {
        ...state,
        step4: {
          brief: payload.content,
        },
        paragraph: payload.content,
      };

    case actionTypes.ADD_OUTLINE_TITLE:
      return {
        ...state,
        step2: {
          ...state.step2,
          output: {
            outlines: payload?.sections,
          },
        },
      };

    case actionTypes.SECTION_UP_SUCCESS:
    case actionTypes.SECTION_DOWN_SUCCESS:
    case actionTypes.SECTION_DELETE_SUCCESS:
      return {
        ...state,
        step2: {
          ...state.step2,
          output: {
            outlines: payload,
          },
        },
      };

    case actionTypes.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        project_id: payload?.project_id,
        step2: {
          ...state.step2,
          intro_id: payload?.intro_id,
          output: {
            outlines: payload?.outlines,
          },
        },
        // project: payload
      };

    case actionTypes.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        updatedProject: payload,
      };

    case actionTypes.EMPTY_CREATED_PROJECT_STATE:
      return {
        ...state,
        project_id: "",
        edit: false,
        step1: {
          id: 1,
          brief: "",
          type: "",
          output: {
            titles: [],
          },
        },
        step2: {
          id: 2,
          brief: "",
          type: "",
          loader: false,
          output: {
            outlines: [],
          },
        },
        step3: {
          id: 3,
          brief: "",
          type: "",
          output: {
            outlines: [],
          },
        },
        step4: {
          id: 4,
          brief: "",
        },
        intro: [],
        paragraph: [],
        customIntro: []
      };

    case actionTypes.SET_SELECTED_PROJECT:
      return {
        ...state,
        project_id: payload?.project_id,
        edit: payload?.edit,
        step1: payload?.step1,
        step2: payload?.step2,
        step3: payload?.step3,
        step4: payload?.step4,
        intro: payload?.intro,
        paragraph: payload?.paragraph,
      };

    case actionTypes.SET_ARTICLE_TOOL_ID:
      return {
        ...state,
        tool_category_id: payload.toolId,
      };

    case actionTypes.UPDATE_CURRENT_STEP:
      return {
        ...state,
        currentStep: payload?.step,
      };

    case actionTypes.UPDATE_STEP_VISITED:
      return {
        ...state,
        stepVisited: payload?.stepVisited,
      };

    case actionTypes.UPDATE_TITLES:
      return {
        ...state,
        step1: {
          ...state?.step1,
          output: payload?.updatedTitles,
        },
      };

    case actionTypes.UPDATE_INTROS:
      return {
        ...state,
        intro: payload?.intro,
      };

      case actionTypes.UPDATE_CUSTOM_INTRO:
      return {
        ...state,
        customIntro: payload?.customIntro,
      };
    default:
      return state;
  }
};

export default blogReducer;
