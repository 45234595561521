import { PinDrop } from "@mui/icons-material";
import * as component from "./lazyProjects";


export const routes = [
  {
    path: "/dashboard",
    component: <component.Dashboard />,
  },
  {
    path: "/my-projects",
    component: <component.Projects />,
  },
  {
    path: "/how-it-works",
    component: <component.HowItWorks />,
  },
  {
    path: "/whats-new",
    component: <component.WhatsNew />,
  },
  {
    path: "/account",
    component: <component.Account />,
  },
  {
    path: "/pricing",
    component: <component.Pricing />,
  },
  {
    path: "/create-blog-inner",
    component: <component.CreateBlogInner />,
  },
  {
    path: "/tools/:toolName/:toolId/:projectId",
    component: <component.ToolNavigator />,
  },
  {
    path: "/tools/:toolName/:toolId",
    component: <component.ToolNavigator />,
  },
  {
    path: "/graphic-ai",
    component: <component.GraphicAI/>,
  },
  //ai-ppt-generator
  { path: "/ppt-generator", component: <component.PPTGeneratorMain /> },
  {
    path: "/tools",
    component: <component.HowItWorks />,
  },
];

export const projectRoute = [
  {
    path: "description-writer",
    component: <component.DescriptionWriter />,
  },
  {
    path: "blog-section",
    component: <component.BlogSection />,
  },
  {
    path: "blog-ideas",
    component: <component.BlogIdea />,
  },
  {
    path: "taking-points",
    component: <component.Comingsoon />,
  },
  {
    path: "blog-intros",
    component: <component.BlogIntro />,
  },
  {
    path: "blog-outlines",
    component: <component.BlogOutline />,
  },
  {
    path: "blog-conclusions",
    component: <component.BlogConclusion />,
  },
  {
    path: "paragraph-writer",
    component: <component.ParagraphWriter />,
  },
  {
    path: "article-rewriter",
    component: <component.Comingsoon />,
  },
  {
    path: "blog-section",
    component: <component.BlogSection />,
  },
  {
    path: "blog-titles",
    component: <component.BlogTitle />,
  },
  {
    path: "cold-email",
    component: <component.ColdEmail />,
  },
  {
    path: "creative-storie",
    component: <component.CreativeStorie />,
  },
  {
    path: "follow-up-email",
    component: <component.FollowUpEmail />,
  },
  {
    path: "graphic-image-generator",
    component: <component.GraphicImageGenerator />,
  },
  {
    path: "instagram-caption",
    component: <component.InstagramCaption />,
  },
  {
    path: "keyword-idea",
    component: <component.KeywordIdea />,
  },
  {
    path: "meta-description",
    component: <component.MetaDescription />,
  },
  {
    path: "meta-title",
    component: <component.MetaTitle />,
  },
  {
    path: "rewrite-keyword",
    component: <component.RewriteKeyword />,
  },
  {
    path: "sentence-expander",
    component: <component.SentenceExpander />,
  },
  {
    path: "social-media-post",
    component: <component.SocialMediaPost />,
  },
  {
    path: "testimonial-review",
    component: <component.TestimonialReview />,
  },
  {
    path: "welcome-email",
    component: <component.WelcomeEmail />,
  },
  {
    path: "article-rewriter",
    component: <component.ArticleRewriter />,
  },
  {
    path: "article-generator",
    component: <component.BlogPost />,
  },
  // {
  //   path: "article-generator",
  //   component: <component.ArticleGenerator />,
  // },
  {
    path: "description-writer",
    component: <component.DescriptionWriter />,
  },
  {
    path: "quora-answer",
    component: <component.QuoraAnswer />,
  },
];


export default routes;
