import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

// pages
import Website from "./Website";

// redux
import store from "../redux/store";

import LoadingCover from "../components/Loadingcover";
import { ErrorBoundary } from "./ErrorBoundary";

const Main = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<LoadingCover />}>
        <Router>
          <ErrorBoundary>
            <Website />
          </ErrorBoundary>
        </Router>
      </Suspense>
    </Provider>
  );
};

export default Main;
