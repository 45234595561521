import * as actionTypes from "../constants";

const initialState = {
  loading: false,
  plans: [],
  paymentUrl: {},
};

const pricingReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.CREATING_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.FETCH_PLANS_SUCCESS:
      return {
        ...state,
        plans: payload,
      };

    case actionTypes.CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        paymentUrl: payload,
        loading: false,
      };

      case actionTypes.CREATE_SUBSCRIPTION_FAIL:
        return {
          ...state,
          loading: false,
        };

    default:
      return state;
  }
};

export default pricingReducer;
