import LoadingCover from "components/Loadingcover";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    const { loading, isAuth } = useSelector((state) => state.authReducer);
    const location = useLocation();

    if (loading) {
        return  <LoadingCover/>;
    }

    if (!isAuth) {
        return <Navigate to="/home" state={{ from: location }} />;
    }

    return (
        <div  className="scroll-smooth relative overflow-y-auto lg:px-12 px-3 py-7 pb-1">
        {children}
        </div>
    );
};

export default PrivateRoute;
