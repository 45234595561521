import waveHand from "images/wave-hand.png";
import dashboard from "images/dashboard-icon.svg";
import projectIcon from "images/projectIcon.svg";
import menuIcon1 from "images/Blog-ideas-icon.svg"
import menuIcon2 from "images/menuIcon02.svg"
import menuIcon3 from "images/menuIcon03.svg"
import menuIcon4 from "images/menuIcon04.svg"
import menuIcon5 from "images/menuIcon05.svg"
import menuIcon7 from "images/menuIcon07.svg"
import menuIcon8 from "images/menuIcon08.svg"
import articLIcon from "images/Articalicon.svg"
import graphicIcon from "images/icon.svg"
import UserIcon from "images/user.svg"

import faq from "images/FAQs-icon.svg";
import BlogIdeaIcon from "images/BlogIdeaIcon.js";
import TakingPointIcon from "images/TakingPointIcon.js";
import BlogTitleIcon from "images/BlogTitleIcon.js";
import BlogIntroIcon from "images/BlogIntroIcon.js";
import BlogConclusionIcon from "images/BlogConclusionIcon.js";
import ArticleRewriterIcon from "images/ArticleRewriterIcon.js";
import Card1 from "images/icon.png";
import Card2 from "images/icon (1).png";
import Card3 from "images/icon (2).png";
import User1 from "images/user1.png";
import User2 from "images/user2.png";
import User3 from "images/user3.png";
import User4 from "images/user4.png";


export const engines = [
  "text-davinci-002",
  "text-curie-001",
  "text-babbage-001",
  "text-davinci-001",
];

export const outputs = ["1", "2", "3"];

export const title = {
  temperature:
    "Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive.",
  length:
    "The maximum number of tokens to generate. Requests can use upto 2,048 or 4,000 tokens shared between prompt and completion. The exact limit varies by model. (One token is roughly 4 characters for normal English text)",
  top: "Controls diversity via nucleus sampling: 0.5 means half of all likelihood- weighted options are considered.",
};

export const getRandomEngine = () => {
  let randomNumber = Math.floor(Math.random() * engines.length);
  return engines[randomNumber];
};




export const allTools = [
  {
    id: 3,
    title: "Blog Ideas",
    to: "/blog-ideas",
    icon: TakingPointIcon,
  },
  {
    id: 4,
    title: "Taking Points",
    to: "/taking-points",
    icon: TakingPointIcon,
  },
  {
    id: 5,
    title: "Blog Titles",
    to: "/blog-titles",
    icon: BlogTitleIcon,
  },
  {
    id: 6,
    title: "Blog Intros",
    to: "/blog-intros",
    icon: BlogIntroIcon,
  },
  {
    id: 7,
    title: "Blog Outlines",
    to: "/blog-outlines",
    icon: BlogIdeaIcon,
  },
  {
    id: 8,
    title: "Blog Conclusions",
    to: "/blog-conclusions",
    icon: BlogConclusionIcon,
  },
  {
    id: 9,
    title: "Paragraph Writer",
    to: "/paragraph-writer",
    icon: BlogIdeaIcon,
  },
  {
    id: 10,
    title: "Article Rewriter",
    to: "/article-rewriter",
    icon: ArticleRewriterIcon,
  },
  {
    id: 11,
    title: "Blog Section",
    to: "/blog-section",
    icon: BlogIdeaIcon,
  },
  {
    id: 12,
    title: "Quora Answers",
    to: "/quora-answers",
    icon: BlogIdeaIcon,
  },
  {
    id: 13,
    title: "Description Writer",
    to: "/description-writer",
    icon: BlogIdeaIcon,
  },
  {
    id: 14,
    title: "Cold Emails",
    to: "/cold-emails",
    icon: BlogIdeaIcon,
  },
  {
    id: 15,
    title: "Creative Stories",
    to: "/creative-stories",
    icon: BlogIdeaIcon,
  },
  {
    id: 16,
    title: "Follow up Emails",
    to: "/follow-up-emails",
    icon: BlogIdeaIcon,
  },
  {
    id: 17,
    title: "Graphic/Image generator",
    to: "/graphic-image-generator",
    icon: BlogIdeaIcon,
  },
  {
    id: 18,
    title: "Instagram Captions",
    to: "/instagram-captions",
    icon: BlogIdeaIcon,
  },
  {
    id: 19,
    title: "Keyword Ideas",
    to: "/keyword-ideas",
    icon: BlogIdeaIcon,
  },
  {
    id: 20,
    title: "Meta Description",
    to: "/meta-description",
    icon: BlogIdeaIcon,
  },
  {
    id: 21,
    title: "Meta Titles",
    to: "/meta-titles",
    icon: BlogIdeaIcon,
  },
  {
    id: 23,
    title: "Rewrite With Keyword",
    to: "/rewrite-with-keyword",
    icon: BlogIdeaIcon,
  },
  {
    id: 24,
    title: "Sentence Expander",
    to: "/sentence-expander",
    icon: BlogIdeaIcon,
  },

  {
    id: 25,
    title: "Social Media Post",
    to: "/social-media-post",
    icon: BlogIdeaIcon,
  },
  {
    id: 26,
    title: "Testimonials / Reviews",
    to: "/testimonial",
    icon: BlogIdeaIcon,
  },
  {
    id: 27,
    title: "Welcome Email",
    to: "/welcome-email",
    icon: BlogIdeaIcon,
  },
];




export const header = [
  {
    id: 1,
    title: "Dashboard",
    src: dashboard,
    to: "/dashboard",
    dropDown: false,
  },
  {
    id: 2,
    title: "Projects",
    src: projectIcon,
    to: "/my-projects",
    dropDown: false,
  },
  {
    id: 3,
    title: "Tools",
    src: projectIcon,
    dropDown: true,
    submenus: [
      {
        id: 4,
        title: "Blog Ideas",
        to: "/tools/welcome-email/6396bd5931821430297a2b27/",
        src: menuIcon1
      },
      {
        id: 5,
        title: "Taking Points",
        to: "/tools/submenu-item-2",
        src: menuIcon2
      },
      {
        id: 6,
        title: "Blog Titles (Listicles)",
        to: "/tools/submenu-item-2",
        src: menuIcon3
      },
      {
        id: 7,
        title: "Blog Intros",
        to: "/tools/submenu-item-2",
        src: menuIcon4
      },
      {
        id: 8,
        title: "Blog Outlines",
        to: "/tools/submenu-item-2",
        src: menuIcon5
      },
      {
        id: 9,
        title: "Blog Conclusions",
        to: "/tools/submenu-item-2",
        src: menuIcon8
      },
      {
        id: 10,
        title: "Paragraph Writer",
        to: "/tools/submenu-item-2",
        src: menuIcon7
      },
      {
        id: 11,
        title: "Article Rewriter",
        to: "/tools/submenu-item-2",
        src: menuIcon8
      },
      
    ],
  },
  {
    id: 12,
    title: "Article Generator",
    src: articLIcon,
    to: "/tools/article-generator/6396cc48e85a114a2d774653/",
    dropDown: false,
  },
  {
    id: 13,
    title: "Graphic AI",
    src: graphicIcon,
    to: "/graphic-ai",
    dropDown: false,
  },
  {
    id: 14,
    title: "How it works",
    src: faq,
    to: "/how-it-works",
    dropDown: false,
  },
  {
    id: 15,
    title: "My Account",
    src: UserIcon,
    to: "/profile",
    dropDown: false,
  },
  {
    id: 16,
    title: "AI - Presentation Generator",
    src: dashboard, 
    to: "/ppt-generator",
    dropDown: false,
  },
];



export const content = {
  icon: waveHand,
  title: "Welcome To New Age Content Generation GenXContent!",
};

export const menus = [
  "All",
  "Blog Content",
  "Website Copy & SEO",
  "SocialMedia & Ads",
  "Marketing",
  "More Tools",
];

export const cards = [
  // {
  //     id: 1,
  //     title: "Blog Post Writer",
  //     subtitle: "Write blog articles in minutes with the most advanced AI writing assistant.",
  //     leftIcon: "list2",
  //     rightIcon: "topRightArrow"
  // },
  {
    id: 2,
    src: "art-writer",
    title: "Article Generator",
    subtitle:
      "Turn a title and an outline into a fully SEO-optimized and long article with this AI editor.",
    leftIcon: "calendar",
    rightIcon: "topRightArrow",
    newSale: true,
  },
  {
    id: 3,
    src: "prgh-writer",
    title: "Paragraph Writer",
    subtitle:
      "Write original and human-like paragraphs based on your blog subheading (h2, h3, h4...).",
    leftIcon: "list2",
    rightIcon: "topRightArrow",
    newSale: true,
  },
  {
    id: 4,
    src: "dscr-writer",
    title: "Article Rewriter",
    subtitle:
      "The smartest article rewriter ever.Rewrite blog articles or any type of content in seconds.",
    leftIcon: "sync",
    rightIcon: "topRightArrow",
    newSale: true,
  },
  {
    id: 5,
    src: "title-generator",
    title: "Blog Section",
    subtitle:
      "Write a full blog section (few paragraphs) about a subheading of your article.",
    leftIcon: "calendar",
    rightIcon: "topRightArrow",
    newSale: false,
  },
  {
    id: 6,
    src: "outline-writer",
    title: "Blog Titles",
    subtitle:
      "Nobody wants to read boring blog titles, generate catchy blog titles with this tool.",
    leftIcon: "calendar",
    rightIcon: "topRightArrow",
    newSale: false,
  },
  {
    id: 7,
    src: "cons-writer",
    title: "Blog Ideas",
    subtitle:
      "The perfect tool to start writing great articles. Generate creative ideas for your next post.",
    leftIcon: "tips",
    rightIcon: "topRightArrow",
    newSale: false,
  },
  {
    id: 8,
    src: "quora-answer",
    title: "Blog Titles (Listicles)",
    subtitle:
      "Automatically generate engaging listicle headlines, the most effective type of title.",
    leftIcon: "calendar",
    rightIcon: "topRightArrow",
    newSale: false,
  },
  {
    id: 9,
    src: "quora-answer",
    title: "Blog Intros",
    subtitle:
      "Write an intro that will entice your visitors to read more about your article.",
    leftIcon: "calendar",
    rightIcon: "topRightArrow",
    newSale: false,
  },
  {
    id: 10,
    src: "quora-answer",
    title: "Blog Outlines",
    subtitle:
      "Write the general idea of your next blog post. Outlining made easier and faster.",
    leftIcon: "list2",
    rightIcon: "topRightArrow",
    newSale: false,
  },
  {
    id: 11,
    src: "quora-answer",
    title: "Blog Conclusions",
    subtitle: "End your blog articles with an engaging conclusion paragraph.",
    leftIcon: "flag",
    rightIcon: "topRightArrow",
    newSale: false,
  },
  {
    id: 12,
    src: "quora-answer",
    title: "Taking Points",
    subtitle:
      "Write short, simple and informative points for the subheadings of your article.",
    leftIcon: "list2",
    rightIcon: "topRightArrow",
    newSale: false,
  },
];

export const articleForm = {
  title: "Generate Articles With AI",
  subtitle: "Turn a title and outline into a long and engaging article.",
};

export const articleLangs = ["usEnglish(US)", "gbEnglish(UK)"];

export const articleFocusedKeywords = ["travelling europe", "nature lover"];

export const articleSubHeadings = [
  "Paris",
  "Barcelone",
  "Venice",
  "Amsterdam",
  "Berlin",
];

export const playground = {
  playground: "Playground",
  placeholder: "Write a tagline for an ice cream shop",
  button: "Submit",
  cancel: "Cancel",
  model: "Model",
  temperature: "Temperature",
  temp: "0.7",
  length: "Maximum length",
  lg: "256",
  top: "Top P",
  topNumber: "1",
  outputsTag: "Number of outputs",
  outputs: "7",
};

export const blogOutline = [
  {
    id: 1,
    name: "Rock Garden",
    checked: false,
    edit: false,
  },
  {
    id: 2,
    name: "Sector 17",
    checked: false,
    edit: false,
  },
  {
    id: 3,
    name: "Sectro 15",
    checked: false,
    edit: false,
  },
  {
    id: 4,
    name: "Sukhna lake",
    checked: false,
    edit: false,
  },
  {
    id: 5,
    name: "Isckon temple",
    checked: false,
    edit: false,
  },
  {
    id: 6,
    name: "Elante mall",
    checked: false,
    edit: false,
  },
];

export const introOutline = [
  "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
  "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
];

export const industry = [
  { id: 0, value: "", label: "None" },
  {
    id: 1,
    value: "Travel and Tourism",
    label: "Travel and Tourism",
  },
  {
    id: 2,
    value: "Shopping",
    label: "Shopping",
  },
  {
    id: 3,
    value: "Health and Wellness",
    label: "Health and Wellness",
  },
  {
    id: 4,
    value: "Politics and Current Events",
    label: "Politics and Current Events",
  },
  {
    id: 5,
    value: "Quotes",
    label: "Quotes",
  },
  {
    id: 6,
    value: "Food and Cooking",
    label: "Food and Cooking",
  },
  {
    id: 7,
    value: "Real Estate",
    label: "Real Estate",
  },
  {
    id: 8,
    value: "Technology",
    label: "Technology",
  },
  {
    id: 9,
    value: "Business",
    label: "Business",
  },
  {
    id: 10,
    value: "Sports",
    label: "Sports",
  },
  {
    id: 11,
    value: "Service",
    label: "Service",
  },
  {
    id: 12,
    value: "Auto Mobile",
    label: "Auto Mobile",
  },
  {
    id: 13,
    value: "Fashion and beauty",
    label: "Fashion and beauty",
  },
  {
    id: 14,
    value: "Arts and Entertainment",
    label: "Arts and Entertainment",
  },
  {
    id: 15,
    value: "Education",
    label: "Education",
  },
  {
    id: 16,
    value: "Environment and Sustainability",
    label: "Environment and sustainability",
  },
  {
    id: 17,
    value: "Law and government",
    label: "Law and government",
  },
  {
    id: 18,
    value: "Social media and Online Communities",
    label: "Social media and Online Communities",
  },
  {
    id: 19,
    value: "Science and Research",
    label: "Science and Research",
  },
  {
    id: 20,
    value: "Personal Development and Self-improvement",
    label: "Personal Development and Self-improvement",
  },
  {
    id: 21,
    value: "Energy and Utilities",
    label: "Energy and Utilities",
  },
  {
    id: 22,
    value: "Marketing and Advertising",
    label: "Marketing and Advertising",
  },
];

export const engineType = [
  {
    id: 1,
    value: "Realistic",
    label: "Realistic",
  },
  {
    id: 2,
    value: "Fictional",
    label: "Fictional",
  },
];

export const style = [
  {
    value: "",
    label: "None",
  },
  {
    id: 1,
    value: "3d Render",
    label: "3d Render",
  },
  {
    id: 2,
    value: "Canvas",
    label: "Abstract",
  },
  {
    id: 3,
    value: "Anime",
    label: "Anime",
  },
  {
    id: 4,
    value: "Art Deco",
    label: "Art Deco",
  },
  {
    id: 5,
    value: "Cartoon",
    label: "Cartoon",
  },
  {
    id: 6,
    value: "Digital Art",
    label: "Digital Art",
  },
  {
    id: 7,
    value: "Illustration",
    label: "Illustration",
  },
  {
    id: 8,
    value: "Line Art",
    label: "Line Art",
  },
  {
    id: 9,
    value: "One Line Drawing",
    label: "One Line Drawing",
  },
  {
    id: 10,
    value: "Origami",
    label: "Origami",
  },
  {
    id: 11,
    value: "Pixel Art",
    label: "Pixel Art",
  },
  {
    id: 12,
    value: "Unreal Engine",
    label: "Unreal Engine",
  },
  {
    id: 13,
    value: "Pop Art",
    label: "Pop Art",
  },
  {
    id: 14,
    value: "Retro",
    label: "Retro",
  },
  {
    id: 15,
    value: "Vaporwave",
    label: "Vaporwave",
  },
];

export const Medium = [
  {
    value: "",
    label: "None",
  },
  {
    id: 1,
    value: "Acrylics",
    label: "Acrylics",
  },
  {
    id: 2,
    value: "Canvas",
    label: "Canvas",
  },
  {
    id: 3,
    value: "Chalk",
    label: "Chalk",
  },
  {
    id: 4,
    value: "Charcoal",
    label: "Charcoal",
  },
  {
    id: 5,
    value: "Classic Oil",
    label: "Classic Oil",
  },
  {
    id: 6,
    value: "Crayon",
    label: "Crayon",
  },
  {
    id: 7,
    value: "Glass",
    label: "Glass",
  },
  {
    id: 8,
    value: "Ink",
    label: "Ink",
  },
  {
    id: 9,
    value: "Modern Oil Painting",
    label: "Modern Oil Painting",
  },
  {
    id: 10,
    value: "Pastel",
    label: "Pastel",
  },
  {
    id: 11,
    value: "Pencil",
    label: "Pencil",
  },
  {
    id: 12,
    value: "Spray Paint",
    label: "Spray Paint",
  },
  {
    id: 13,
    value: "Water Color Painting",
    label: "Water Color Painting",
  },
  {
    id: 14,
    value: "Wood Panel",
    label: "Wood Panel",
  },
];

export const Artist = [
  {
    value: "",
    label: "None",
  },
  {
    id: 1,
    value: "Andy Warhol",
    label: "Andy Warhol",
  },
  {
    id: 2,
    value: "Ansel Adams",
    label: "Ansel Adams",
  },
  {
    id: 3,
    value: "Claude Monet",
    label: "Claude Monet",
  },
  {
    id: 4,
    value: "Dr. Seuss",
    label: "Dr. Seuss",
  },
  {
    id: 5,
    value: "Pablo Picasso",
    label: "Pablo Picasso",
  },
  {
    id: 6,
    value: "Pixar",
    label: "Pixar",
  },
  {
    id: 7,
    value: "Salvador Dali",
    label: "Salvador Dali",
  },
  {
    id: 8,
    value: "South Park",
    label: "South Park",
  },
  {
    id: 9,
    value: "Van Gogh",
    label: "Van Gogh",
  },
];

export const Mood = [
  {
    value: "",
    label: "None",
  },
  {
    id: 1,
    value: "Aggressive",
    label: "Aggressive",
  },
  {
    id: 2,
    value: "Angry",
    label: "Angry",
  },
  {
    id: 3,
    value: "Boring",
    label: "Boring",
  },
  {
    id: 4,
    value: "Bright",
    label: "Bright",
  },
  {
    id: 5,
    value: "Calm",
    label: "Calm",
  },
  {
    id: 6,
    value: "Cheerful",
    label: "Cheerful",
  },
  {
    id: 7,
    value: "Chilling",
    label: "Chilling",
  },
  {
    id: 8,
    value: "Colorful",
    label: "Colorful",
  },
  {
    id: 9,
    value: "Dark",
    label: "Dark",
  },
  {
    id: 10,
    value: "Neutral",
    label: "Neutral",
  },
];

export const Details = [
  {
    value: "",
    label: "None",
  },
  {
    id: 1,
    value: "Ambient light",
    label: "Ambient light",
  },
  {
    id: 2,
    value: "Black & White",
    label: "Black and White",
  },
  {
    id: 3,
    value: "Close-up",
    label: "Close-up",
  },
  {
    id: 4,
    value: "Full face portrait",
    label: "Full face portrait",
  },
  {
    id: 5,
    value: "High resolution",
    label: "High resolution",
  },
  {
    id: 6,
    value: "Highly-detailed",
    label: "Highly-detailed",
  },
  {
    id: 7,
    value: "Photorealistic",
    label: "Photorealistic",
  },
  {
    id: 8,
    value: "Realistic",
    label: "Realistic",
  },
  {
    id: 9,
    value: "Sharp",
    label: "Sharp",
  },
];

export const descriptionOptions = [
  {
    id: 1,
    value: "Classified Ad",
    label: "Classified Ad",
  },
  {
    id: 2,
    value: "Social Bookmarking",
    label: "Social Bookmarking",
  },
  {
    id: 3,
    value: "Image Sharing",
    label: "Image Sharing",
  },
];

export const rowPerPageOptions = [
  { value: 10, label: "10 Rows" },
  { value: 20, label: "20 Rows" },
  { value: 50, label: "50 Rows" },
  { value: 100, label: "100 Rows" },
];

export const ratingOptions = [
  { value: "", label: "All - Ratings" },
  { value: "0-1", label: "0-1 Rating" },
  { value: "1-2", label: "1-2 Rating" },
  { value: "2-3", label: "2-3 Rating" },
  { value: "3-4", label: "3-4 Rating" },
  { value: "4-5", label: "4-5 Rating" },
];

export const orderOptions = [
  { value: "", label: "None" },
  { value: "asc", label: "ASC" },
  { value: "desc", label: "DESC" },
];

export const voiceToneOptions = [
  { value: "Professional", label: "Professional" },
  { value: " Childish", label: "Childish" },
  { value: "Luxurious ", label: "Luxurious" },
  { value: " Friendly", label: "Friendly" },
  { value: " Confident", label: "Confident" },
  { value: " Exciting", label: "Exciting" },
];

export const pointOfViewOptions = [
  { value: 1, label: "First Person" },
  { value: 0, label: "Third Person" },
];

export const goalOfTheEmail = [
  { value: "Start free trial", label: "Start free trial" },
  { value: "Sales", label: "Sales" },
  { value: "Demo", label: "Demo" },
  { value: "Schedule a call", label: "Schedule a call" },
  { value: "Sign up", label: "Sign up" },
  { value: "Collaboration", label: "Collaboration" },
  { value: "Sponsorship", label: "Sponsorship" },
  { value: "Backlink", label: "Backlink" },
  { value: "Job offer", label: "Job offer" },
];

export const outlineGeneratorOptions = [
  { value: "images", label: "Images" },
  { value: "instructions", label: "Instructions" },
  { value: "keywords", label: "Keywords" },
];

export const textLengthOptions = [
  { value: "short", label: "Short" },
  { value: "medium", label: "Medium" },
  { value: "long", label: "Long" },
];

export const creativityOptions = [
  { value: "Regular", label: "Regular" },
  { value: "High", label: "High" },
];

export const wordsData = [
  {
    img: Card1,
    heading: "Article Generator",
    partOfSpeech:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua dolor sit amet",
  },
  {
    img: Card2,
    heading: "Blog Conclusions",
    partOfSpeech:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua dolor sit amet",
  },
  {
    img: Card2,
    heading: "Blog Intros",
    partOfSpeech:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua dolor sit amet",
  },
  {
    img: Card3,
    heading: "Blog Titles",
    partOfSpeech:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua dolor sit amet",
  },
];

export const ReviewData = [
  {
    img: User1,
    heading: "Lorem ipsum dolor sit amet, so consectetur adipiscing elit",
    name:"Austin Doherty",
    partOfSpeech:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua dolor sit amet",
    starRating: 2,
  },
  {
    img: User2,
    heading: "Lorem ipsum dolor sit amet, so consectetur adipiscing elit",
    name:"Austin Doherty",
    partOfSpeech:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua dolor sit amet",
    starRating: 4,
  },
  {
    img: User3,
    heading: "Lorem ipsum dolor sit amet, so consectetur adipiscing elit",
    name:"Austin Doherty",
    partOfSpeech:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua dolor sit amet",
    starRating: 5,
  },
  {
    img: User4,
    heading: "Lorem ipsum dolor sit amet, so consectetur adipiscing elit",
    name:"Austin Doherty",
    partOfSpeech:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua dolor sit amet",
    starRating: 5,
  },
];
