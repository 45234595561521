import React from "react";
import cover from "images/loaderBackground.jpg";
import loader from "images/loader.gif";

const LoadingCover = () => {
  return (
    <div className="w-screen h-screen bg-gradient-primary flex justify-center items-center">
      <p className="text-4xl text-white font-bold mr-2">Contentmate.ai</p>
      {/* <p className="animate-bounce text-4xl text-white font-bold mr-2"> .</p>
      <p className="animate-bounce text-4xl text-white font-bold mr-2"> .</p>
      <p className="animate-bounce text-4xl text-white font-bold mr-2"> .</p> */}
      <img className="w-20 h-4" src={loader} />
    </div>
  );
};

export default LoadingCover;
