import { lazy } from "react";

// page folder
export const Dashboard = lazy(() => import("pages/Dashboard"));
export const ComingSoon = lazy(() => import("pages/ComingSoon"));
export const Projects = lazy(() => import("pages/Projects"));
export const MyContent = lazy(() => import("pages/MyContent"));
export const HowItWorks = lazy(() => import("pages/HowItWorks"));
export const NotFound = lazy(() => import("pages/NotFound"));
export const WhatsNew = lazy(() => import("pages/WhatsNew"));
export const ArticleGenerator = lazy(() => import("pages/ArticleGenerator"));
export const BlogPost = lazy(() => import("pages/BlogPost"));
export const Home = lazy(() => import("pages/Home"));
export const Login = lazy(() => import("pages/Auth/Login"));
export const Register = lazy(() => import("pages/Auth/Register"));
export const Account = lazy(() => import("pages/Auth/Account"));
export const Pricing = lazy(() => import("pages/Pricing"));
export const CreateBlogInner = lazy(() => import("pages/CreateBlogInner/CreateBlogInner"));


export const Profile = lazy(() => import("pages/Profile"));
export const Contactus = lazy(() => import("pages/ContactUs"));
export const Comingsoon = lazy(() => import("pages/ComingSoon"));
export const BlogConclusion = lazy(() =>
  import("pages/ProjectCategory/BlogConclusion")
);
export const BlogIntro = lazy(() => import("pages/ProjectCategory/BlogIntro"));
export const BlogTitle = lazy(() => import("pages/ProjectCategory/BlogTitle"));
export const ParagraphWriter = lazy(() =>
  import("pages/ProjectCategory/ParagraphWriter")
);
export const BlogSection = lazy(() => import("pages/ProjectCategory/BlogSection"));
export const BlogIdea = lazy(() => import("pages/ProjectCategory/BlogIdea"));
export const BlogOutline = lazy(() => import("pages/ProjectCategory/BlogOutline"));
export const QuoraAnswer = lazy(() => import("pages/ProjectCategory/QuoraAnswer"));
export const DescriptionWriter = lazy(() =>
  import("pages/ProjectCategory/DescriptionWriter")
);
export const ColdEmail = lazy(() => import("pages/ProjectCategory/ColdEmail"));
export const CreativeStorie = lazy(() =>
  import("pages/ProjectCategory/CreativeStorie")
);
export const FollowUpEmail = lazy(() => import("pages/ProjectCategory/FollowUpEmail"));
export const GraphicImageGenerator = lazy(() =>
  import("pages/ProjectCategory/GraphicImageGenerator")
);
export const InstagramCaption = lazy(() =>
  import("pages/ProjectCategory/InstagramCaption")
);
export const KeywordIdea = lazy(() => import("pages/ProjectCategory/KeywordIdea"));
export const MetaDescription = lazy(() =>
  import("pages/ProjectCategory/MetaDescription")
);
export const MetaTitle = lazy(() => import("pages/ProjectCategory/MetaTitle"));
export const RewriteKeyword = lazy(() =>
  import("pages/ProjectCategory/RewriteKeyword")
);
export const SentenceExpander = lazy(() =>
  import("pages/ProjectCategory/SentenceExpander")
);
export const SocialMediaPost = lazy(() =>
  import("pages/ProjectCategory/SocialMediaPost")
);
export const TestimonialReview = lazy(() =>
  import("pages/ProjectCategory/TestimonialReview")
);
export const WelcomeEmail = lazy(() => import("pages/ProjectCategory/WelcomeEmail"));
export const ArticleRewriter = lazy(() =>
  import("pages/ProjectCategory/ArticleRewriter")
);

// Component folder
export const PositionedSidebar = lazy(() => import("components/Sidebar"));
export const PhaseTwoHeader = lazy(() => import("components/Header/phase2"));

// Graphic Image component
export const GraphicAI = lazy(() => import("pages/GraphicAI"));

export const ToolNavigator = lazy(() => import("./ToolsNavigator"));

//ai ppt generator
export const PPTGeneratorMain = lazy(() =>
  import("pages/PPTGenerator/PPTGeneratorMain")
);


