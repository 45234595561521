export const loginPayload = (data) => {
    return {
        email: data.email,
        password: data.password
    }
}

export const signupPayload = (data) => {
    return {
        name : data.name,
        email: data.email,
        password: data.password,
        contact: data.contact
    }
}

export const createPrompt = (prompt, model, temperature, max_tokens, n) => {
    return {
        prompt,
        model,
        temperature,
        max_tokens,
        n
    }
}

export const createBlogPayload = (data, input) => {
    const titles = data.map((ele, idx) => {
        return (
            {
                id: idx + 1,
                name: ele,
                checked: false,
                edit: false
            }
        )
    })
    return {
        brief: input.brief,
        type: input.type,
        target_keywords: input?.target_keywords,
        related_keywords: input?.related_keywords,
        // tool_category_id: input.tool_category_id,
        output: {
            titles,
            customTitle : {
                title: "",
                checked: false
            }
        }
    }
}

export const createOutlineFromTitlePayload = (data, input) => {
    const outlines = data.map((ele, idx) => {
        return (
            {
                id: idx + 1,
                title: ele,
                paragraph: "",
                edit: false,
                tab: 'instructions',
                instructions: '',
                length: '',
                keywords: []
            }
        )
    })
    return {
        brief: input.title,
        type: input.type,
        output: {
            outlines
        }
    }
}

export const createProjectPayload = (data, step2) => {
    const outlines = data.outlines?.map((e, idx) => {
        return {
            ...e,
            title: e?.outline,
            tab: e?.tab,
            instructions: e?.instructions,
            length: e?.length || "Short",
            keywords: e?.keywords,
            keyword: ""
        };
    })
    return {
        intro_id: data.content._id,
        project_id: data?.Project?._id,
        outlines
    }
}

export const getIntroPayload = (data) => {
    return {
        brief: data,
    }
}

export const createIntroFromOutlinePayload = (data) => {
    const A = data.map((ele, idx) => {
        return (
            {
                id: idx + 1,
                title: ele,
                checked: false,
                edit: false
            }
        )
    })
    return A
}

export const createParagraphFromIntroPayload = (data, clickedCard, step2, step4) => {
    let paragraph ='';
    data.map((ele, idx) => {
        paragraph = paragraph + ele + ' ';
    })
    return {
        paragraph: step2.brief === clickedCard.outline ? paragraph?.trim() : step4.brief,
        outlines: step2.output.outlines.map((ele, idx) => {
            return (
                {
                    ...ele,
                    paragraph: ele.title === clickedCard.title ? paragraph?.trim() : ele.paragraph?.trim(),
                }
            )
        })
    }
}

export const getLoadingForParagraph = (data, clickedCard, step2, step4) => {
    return {
        ...step2,
        loader: data.outline === step2.brief ,
        output: {
            outlines: step2.output.outlines.map((ele, idx) => {
                return (
                    {
                        ...ele,
                        loader: ele.title === clickedCard.outline 
                    }
                )
            })
        }
    }

}