import * as actionTypes from "../constants";

const initialState = {
  headerTitle: "Dashboard",
  save: {
    show: false,
    loading: false,
    message: "Saved",
    error: false
  },
  breadcrumbs: "",
};

const headerReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_HEADER_TITLE:
      return {
        ...state,
        headerTitle: payload?.title,
      };

    case actionTypes.SET_SAVING:
      return {
        ...state,
        save: {
          ...state?.save,
          ...payload
        },
      };

    default:
      return state;
  }
};

export default headerReducer;
