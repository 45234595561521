import * as actionTypes from "../constants";
import * as apis from "../requests/apis";
import * as payloads from "../payloads";
import * as services from "../services";
import { apiRequest } from "../requests";

export const authChecking = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.AUTH_CHECKING });
    const res = await apiRequest(actionTypes.GET, apis.GET_USER_API);
    dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: actionTypes.LOGIN_FAIL, payload: err });
  }
};

export const login = (data, message) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.LOGIN_REQUEST });
    const payload = payloads.loginPayload(data);
    const res = await apiRequest(actionTypes.POST, apis.LOGIN_API, payload);
  
    services.setToken(res?.data?.token);
    const res2 = await apiRequest(actionTypes.GET, apis.GET_USER_API);
    dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: res2.data });
    dispatch({
      type: actionTypes.SET_TOASTER,
      payload: {
        active: true,
        message: message,
        type: "success",
      },
    });
  } catch (err) {
    dispatch({ type: actionTypes.LOGIN_FAIL, payload: err });
    const msg = JSON.parse(err?.request?.responseText).errors[0].msg;
    dispatch({
      type: actionTypes.SET_TOASTER,
      payload: {
        active: true,
        message: msg,
        type: "error",
      },
    });
  }
};

export const signup = (data, message) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SIGNUP_REQUEST });
    const payload = payloads.signupPayload(data);
    const res = await apiRequest(actionTypes.POST, apis.SIGNUP_API, payload);
    services.setToken(res?.data?.token);
    const res2 = await apiRequest(actionTypes.GET, apis.GET_USER_API);
    dispatch({ type: actionTypes.SIGNUP_SUCCESS, payload: res2.data });
    dispatch({
      type: actionTypes.SET_TOASTER,
      payload: {
        active: true,
        message: message,
        type: "success",
      },
    });
  } catch (err) {
    dispatch({ type: actionTypes.SIGNUP_FAIL, payload: err });
    const msg = JSON.parse(err?.request?.responseText).errors[0].msg;
    dispatch({
      type: actionTypes.SET_TOASTER,
      payload: {
        active: true,
        message: msg,
        type: "error",
      },
    });
  }
};


export const updateTokensAmount = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATE_USER_TOKEN });
    const res = await apiRequest(actionTypes.GET, apis.GET_USER_API);
    console.log({res})
    dispatch({ type: actionTypes.UPDATE_USER_TOKEN_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: actionTypes.UPDATE_USER_TOKEN_FAILURE, payload: err });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.LOGOUT_REQUEST });
    services.removeToken();
    dispatch({ type: actionTypes.LOGOUT_SUCCESS });
  } catch (err) {
    dispatch({ type: actionTypes.LOGOUT_FAIL, payload: err });
  }
};
