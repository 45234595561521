export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

export const AUTH_CHECKING = "AUTH_CHECKING";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const REQUEST_CREATE_PROMPT = "REQUEST_CREATE_PROMPT";
export const CREATE_PROMPT_SUCCESS = "CREATE_PROMPT_SUCCESS";
export const CREATE_PROMPT_FAIL = "CREATE_PROMPT_FAIL";

export const REQUEST_GET_PROMPTS = "REQUEST_GET_PROMPTS";
export const GET_PROMPTS_SUCCESS = "GET_PROMPTS_SUCCESS";
export const GET_PROMPTS_FAIL = "GET_PROMPTS_FAIL";

export const CREATING_BLOG = "CREATING_BLOG";
export const CREATE_BLOG_POST_SUCCESS = "CREATE_BLOG_POST_SUCCESS";
export const CREATE_BLOG_POST_FAIL = "CREATE_BLOG_POST_FAIL";

export const CREATING_OUTLINE_FROM_TITLE = "CREATING_OUTLINE_FROM_TITLE";
export const CREATE_OUTLINE_FROM_TITLE_SUCCESS = "CREATE_OUTLINE_FROM_TITLE_SUCCESS";
export const CREATE_OUTLINE_FROM_TITLE_FAIL = "CREATE_OUTLINE_FROM_TITLE_FAIL";

export const CREATING_INTRO_FROM_OUTLINE = "CREATING_INTRO_FROM_OUTLINE";
export const CREATING_INTRO_FROM_OUTLINE_WITH_CUSTOM = "CREATING_INTRO_FROM_OUTLINE_WITH_CUSTOM";
export const DELETE_INTRO_FROM_OUTLINE_WITH_CUSTOM = "DELETE_INTRO_FROM_OUTLINE_WITH_CUSTOM";
export const CREATE_INTRO_FROM_OUTLINE_SUCCESS = "CREATE_INTRO_FROM_OUTLINE_SUCCESS";
export const CREATE_INTRO_FROM_OUTLINE_FAIL = "CREATE_INTRO_FROM_OUTLINE_FAIL";

export const CREATING_PARAGRAPH_FROM_INTRO = "CREATING_PARAGRAPH_FROM_INTRO";
export const CREATE_PARAGRAPH_FROM_INTRO_SUCCESS = "CREATE_PARAGRAPH_FROM_INTRO_SUCCESS";
export const CREATE_PARAGRAPH_FROM_INTRO_FAIL = "CREATE_PARAGRAPH_FROM_INTRO_FAIL";
export const UPDATE_OUTLINE_PARAGRAPH = "UPDATE_OUTLINE_PARAGRAPH";
export const UPDATE_OUTLINE = "UPDATE_OUTLINE";
export const UPDATE_INTRO_PARAGRAPH = "UPDATE_INTRO_PARAGRAPH";
export const UPDATE_INTRO = "UPDATE_INTRO";

export const ADD_OUTLINE_TITLE = "ADD_OUTLINE_TITLE";

// updating the token amount as pr hit of open ai
export const UPDATE_USER_TOKEN = "UPDATE_USER_TOKEN"
export const UPDATE_USER_TOKEN_SUCCESS = "UPDATE_USER_TOKEN_SUCCESS"
export const UPDATE_USER_TOKEN_FAILURE = "UPDATE_USER_TOKEN_FAILURE"



export const GET_INTRO_SUCCESS = "GET_INTRO_SUCCESS";
export const GET_INTRO_FAIL = "GET_INTRO_FAIL"

export const SECTION_UP_SUCCESS = "SECTION_UP_SUCCESS";
export const SECTION_UP_FAIL = "SECTION_UP_FAIL";
export const SECTION_DELETE_SUCCESS = "SECTION_DELETE_SUCCESS";
export const SECTION_DELETE_FAIL = "SECTION_DELETE_FAIL";
export const SECTION_DOWN_SUCCESS = "SECTION_DOWN_SUCCESS";
export const SECTION_DOWN_FAIL = "SECTION_DOWN_FAIL";

export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL";
export const EMPTY_CREATED_PROJECT_STATE = "EMPTY_CREATED_PROJECT_STATE";
export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";

export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";
export const UPDATE_IMAGE = "UPDATE_IMAGE";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";


export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_PLANS_FAIL = "FETCH_PLANS_FAIL";

export const CREATING_SUBSCRIPTION_SUCCESS = "CREATING_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAIL = "CREATE_SUBSCRIPTION_FAIL";

export const SHOW_SNACKBAR_SUCCESS = "SHOW_SNACKBAR_SUCCESS";
export const SHOW_SNACKBAR_FAIL = "SHOW_SNACKBAR_FAIL"; 
export const CLEAR_SNACKBAR_MESSAGE = "CLEAR_SNACKBAR_MESSAGE";

// Toaster 
export const SET_TOASTER = "SET_TOASTER";

// ADD Category of tools
export const ADD_TOOLS = "ADD_TOOLS";
export const TOOLS_WITHOUT_PARENT_CATEGORY = "TOOLS_WITHOUT_PARENT_CATEGORY";

// SET CATEGORY ID
export const SET_ARTICLE_TOOL_ID = "SET_ARTICLE_TOOL_ID";
export const SET_PROJECT_TOOL_ID= "SET_PROJECT_TOOL_ID";

// Blog Conclusion 
export const SET_BLOG_PROJECT = "SET_BLOG_PROJECT";
export const EMPTY_PROJECT = "EMPTY_PROJECT";

// Recommended Tools
export const ADD_RECOMMENDED_TOOLS = "ADD_RECOMMENDED_TOOLS";

// Stepper state
export const UPDATE_CURRENT_STEP = "UPDATE_CURRENT_STEP"; 
export const UPDATE_STEP_VISITED = "UPDATE_STEP_VISITED";

// Component Loading
export const COMPONENT_LOADING = "COMPONENT_LOADING";

// Update Title
export const UPDATE_TITLES = "UPDATE_TITLES";

// Update Intro
export const UPDATE_INTROS = "UPDATE_INTROS";
export const UPDATE_CUSTOM_INTRO = "UPDATE_CUSTOM_INTRO";

// Header titile 
export const SET_HEADER_TITLE = "SET_HEADER_TITLE";

// show message on project save like saving , saved (article generator)
export const SET_SAVING = "SET_SAVING"
export const SAVING = "Saving..."
export const SAVED = "Saved"