import * as actionTypes from "../constants";

const initialState = {
  loading: false,
  compLoading: false,
  user_id: "",
  project_id: "",
  tool_category_id: "",
  title: "",
  description: "",
  projectData: [],
};

const projectReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_BLOG_PROJECT:
      return {
        ...state,
        ...payload
      };
      case actionTypes.EMPTY_PROJECT:
      return {
        ...payload
      };
      case actionTypes.SET_PROJECT_TOOL_ID:
        return {
          ...state,
          tool_category_id : payload?.toolId,
          title : payload?.title,
          description : payload?.description
        };
      case actionTypes.COMPONENT_LOADING:
        return {
          ...state,
          compLoading: payload?.compLoading
        }
    default:
      return state;
  }
};

export default projectReducer;
