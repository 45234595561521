import * as actionTypes from "../constants";

const initialState = {
  loading: true,
  amountLoading: false,
  loginRequest: false,
  signupRequest: false,
  logoutRequest: false,
  error: null,
  isAuth: false,
  user: null,
  token: null,
};

const authReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.AUTH_CHECKING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_USER_TOKEN:
      return {
        ...state,
        amountLoading: true,
      };

    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loginRequest: true,
      };

    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loginRequest: false,
        isAuth: true,
        user: payload.user,
        token: payload.user.available_tokens,
        error: null,
      };

    case actionTypes.UPDATE_USER_TOKEN_SUCCESS:
      return {
        ...state,
        token: payload.user.available_tokens,
        amountLoading: false,
      };
    case actionTypes.UPDATE_USER_TOKEN_FAILURE:
      return {
        ...state,
        token: payload,
        amountLoading: false,
      };
    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        loginRequest: false,
        error: payload,
      };

    case actionTypes.SIGNUP_REQUEST:
      return {
        ...state,
        signupRequest: true,
      };

    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        signupRequest: false,
        isAuth: true,
        user: payload.user,
        error: null,
      };

    case actionTypes.SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
        signupRequest: false,
        error: payload,
      };

    case actionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        logoutRequest: true,
      };

    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        logoutRequest: false,
        isAuth: false,
        user: null,
        error: null,
      };

    case actionTypes.LOGOUT_FAIL:
      return {
        ...state,
        logoutRequest: false,
        error: payload,
      };

    case actionTypes.UPDATE_IMAGE:
      return {
        ...state,
        user: { ...state?.user, profile_image: payload },
      };

    case actionTypes.UPDATE_USER_DETAILS:
      return {
        ...state,
        user: { ...state?.user, ...payload },
      };

    default:
      return state;
  }
};

export default authReducer;
