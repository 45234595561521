export const BASE_URL = process.env.REACT_APP_API;
export const FILE_URL = process.env.REACT_APP_BASE_URL;
export const BUCKET_URL = process.env.REACT_APP_BASE_BUCKET_URL;

export const LOGIN_API = `${BASE_URL}/users/login-user`;
export const SIGNUP_API = `${BASE_URL}/users/create-user`;
export const GET_USER_API = `${BASE_URL}/users/get-user`;

// Image and USER
export const UPDATE_USER_IMAGE = `${BASE_URL}/users/profile-image`;

export const UPDATE_USER_API = `${BASE_URL}/users/update-profile`;
export const UPDATE_USER_PASSWORD_API = `${BASE_URL}/users/change-password`;

export const CREATE_PROMPT_API = `${BASE_URL}/prompts/create-prompt`;
export const GET_PROMPTS_API = `${BASE_URL}/prompts/get-prompts`;

export const CREATE_BLOG_POST_API = `${BASE_URL}/completions/create-titles`;
export const CREATE_OUTLINE_FROM_TITLE_API = `${BASE_URL}/completions/create-outlines`;
export const CREATE_INTRO_FROM_OUTLINE_API = `${BASE_URL}/completions/create-intro`;
export const CREATE_PARAGRAPH_FROM_INTRO_API = `${BASE_URL}/projects/create-paragraph`;
export const UPDATE_INTRO_API = `${BASE_URL}/contents`;

export const CREATE_PROJECT_SUCCESS_API = `${BASE_URL}/projects/create-project`;
export const UPDATE_PROJECT_SUCCESS_API = `${BASE_URL}/projects`;

export const FETCH_PLANS_API = `${BASE_URL}/subscriptions/get-plans`;
export const CREATE_SUBSCRIPTION_API = `${BASE_URL}/subscriptions/create-subscription`;

export const CHECK_PASSWORD = `${BASE_URL}/users/check-password`;

// Rating
export const CREATE_RATING = `${BASE_URL}/ratings/submit-rating`;

// Paragraph change
export const UPDATE_OUTLINE = `${BASE_URL}/projects/update-outline`;

// Title
export const UPDATE_TITLE = `${BASE_URL}/projects/update-title`;

// Category of Functionality like Article Generator, Blog section
export const GET_CATEGORY_LIST = `${BASE_URL}/categories/tools`;

// My Projects
export const GET_PROJECTS = `${BASE_URL}/projects/get-projects`;

export const DELETE_PROJECTS = `${BASE_URL}/projects/delete-project`;

export const GET_PROJECT = `${BASE_URL}/projects/get-project`;

// Create or Update outline
export const CREATE_UPDATE_OUTLINE = `${BASE_URL}/projects/create-outline`;

// Delete Outline
export const DELETE_OUTLINE = `${BASE_URL}/projects/delete-outline`;

// Create conclusion
export const CREATE_CONCLUSION_OUTLINE = `${BASE_URL}/completions/create-conclusion`;

// Create paragraph
export const CREATE_PARAGRAPH = `${BASE_URL}/completions/create-paragraph`;

// Generate Blog ideas
export const CREATE_BLOG_IDEAS = `${BASE_URL}/completions/blog-ideas`;

// Quora-answers
export const CREATE_QUORA_ANSWER = `${BASE_URL}/completions/quora-answers`;


export const CREATE_DESCRIPTION_WRITER = `${BASE_URL}/completions/description-writer`;
export const CREATE_DESCRIPTION_WRITER_PROJECT = `${BASE_URL}/projects/create-description-writer`;

// Creative Stories
export const GENERATE_CREATIVE_STORIES = `${BASE_URL}/completions/creative-stories`;

// Generate Sentence
export const GENERATE_SENTENCE = `${BASE_URL}/completions/sentence-expander`;

// Generate Sentence
export const GENERATE_REWRITE_WITH_KEYWORDS = `${BASE_URL}/completions/rewrite-with-keyword`;

// Generate cold Email results
export const GENERATE_COLD_EMAIL = `${BASE_URL}/completions/email-generation`;

// Generate image and return list or urls
export const GENERATE_IMAGE = `${BASE_URL}/images/create-images`;
export const SAVE_IMAGE = `${BASE_URL}/images/save-image`;
export const GET_IMAGES = `${BASE_URL}/images/get-image`;

export const GET_PROFILE_IMAGE = `${FILE_URL}/uploads`;
export const BASE_BUCKET_URL = BUCKET_URL;

// Recommended tools API
export const GET_RECOMMENDED_TOOLS = `${BASE_URL}/categories/recommended`;

// Outline Image Generate
export const GENERATE_OUTLINE_IMAGES = `${BASE_URL}/projects/create-images`;
// Delete outline Image
export const DELETE_OUTLINE_IMAGE = `${BASE_URL}/projects/delete-image`;

// Get Tool Example
export const GET_TOOL_EXAMPLE = `${BASE_URL}/categories/get-example`;

// Get all images
export const GET_ALL_IMAGES = `${BASE_URL}/projects/get-images`;

//Get Image for ai - ppt generator

export const GET_AI_PPT_IMAGES = `${BASE_URL}/pptimages/create-pptimages`;
export const CREATE_CAPTIONS = `${BASE_URL}/completions/create-captions`;

