import * as actionTypes from "../constants";
import * as apis from "../requests/apis";
import { apiRequest } from "../requests";

export const Tools = () => async (dispatch) => {
  try {
    const res = await apiRequest(actionTypes.GET, apis.GET_CATEGORY_LIST);
    const allTools = [];
    const key = "name";
    // Get tools from Category
    res?.data?.tools.map((tool) => allTools.push(...Object.values(tool)[0]));
    // get all acitve tool
    const activeTools = allTools.filter((tools) => tools?.is_active);
    // get distinct tools
    const uniqueTools = [
      ...new Map(activeTools.map((item) => [item[key], item])).values(),
    ];
    // console.log({ uniqueTools });
    dispatch({
      type: actionTypes.TOOLS_WITHOUT_PARENT_CATEGORY,
      payload: { tools: uniqueTools },
    });
    dispatch({ type: actionTypes.ADD_TOOLS, payload: res?.data?.tools });
  } catch (err) {
    console.log({ err });
  }
};

export const recommdedTools = () => async (dispatch) => {
  try {
    const res = await apiRequest(actionTypes.GET, apis.GET_RECOMMENDED_TOOLS);
    dispatch({
      type: actionTypes.ADD_RECOMMENDED_TOOLS,
      payload: res?.data?.result,
    });
  } catch (err) {
    console.log({ err });
  }
};

export const updateTokensAmount = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATE_USER_TOKEN });
    const res = await apiRequest(actionTypes.GET, apis.GET_USER_API);
    console.log({res})
    dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: actionTypes.LOGIN_FAIL, payload: err });
  }
};
