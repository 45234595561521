import * as actionTypes from "../constants";

const initialState = {
  tools: [],
  toolsWithoutParentCategory: [],
  recommendedTools : [], 
};

const toolReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.ADD_TOOLS:
      return {
        ...state,
        tools: payload,
      };
      case actionTypes.TOOLS_WITHOUT_PARENT_CATEGORY:
      return {
        ...state,
        toolsWithoutParentCategory: payload?.tools,
      };
      case actionTypes.ADD_RECOMMENDED_TOOLS:
      return {
        ...state,
        recommendedTools: payload,
      };
    default:
      return state;
  }
};

export default toolReducer;
