import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import BlogIntro from './pages/BlogSection/BlogIntro';
// import BlogOutlines from './pages/BlogSection/BlogOutlines';
// import QuoraAnswers from './pages/BlogSection/QuoraAnswers';
// import BlogSection from './pages/BlogSection/BlogSection';
import Main from './pages/Main';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);
