import axios from "axios";
import * as services from "../services";
import * as data from "../../data";

const OPEN_AI = process.env.REACT_APP_OPEN_AI;
const OPEN_AI_TOKEN = process.env.REACT_APP_OPEN_AI_TOKEN;

export const apiRequest = (method, url, data,image=false) => {
    const token = services.getToken();
    return axios({
        headers: {
            "content-type": image ? 'application/x-www-form-urlencoded; charset=UTF-8': "application/json",
            "x-auth-token": token
        },
        method,
        url,
        data,
    });
};



export const openAIRequest = (method, payload) => {
    return axios({
        headers: {
            "content-type": "application/json",
            "Authorization": OPEN_AI_TOKEN
        },
        method,
        url: `${OPEN_AI}/engines/${data.getRandomEngine()}/completions`,
        data: payload,
    });
}

export const blogAIRequest = (method, url, payload) => {
    return axios({
        headers: {
            "Content-Type": "application/json",
            "Authorization": OPEN_AI_TOKEN
        },
        method,
        url: `${OPEN_AI}/engines/text-curie-001/completions`,
        data: payload,
    });
}

export const titlesAIRequest = (method, url, payload) => {
    return axios({
        headers: {
            "Content-Type": "application/json",
            "Authorization": OPEN_AI_TOKEN
        },
        method,
        url: `${OPEN_AI}/engines/text-davinci-002/completions`,
        data: payload,
    });
}