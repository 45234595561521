import cookie from "react-cookies";

export const setToken = (token) => {
  return cookie.save("token", token, { path: "/" });
};

export const getToken = () => {
  return cookie.load("token");
};

export const removeToken = () => {
    return cookie.remove('token', { path: '/' })
}

export const setBackForm = (backForm) => {
  return cookie.save("backForm", backForm, { path: "/" });
}

export const getBackForm = () => {
  const backForm = cookie.load("backForm");
  if(backForm === "true") return true;
  else return false;
}

export const setNextForm = (nextForm) => {
  return cookie.save("nextForm", nextForm, { path: `/` });
}

export const getNextForm = () => {
  const nextForm = cookie.load("nextForm");
  if(nextForm === "true") return true;
  else return false;
}