import { combineReducers } from "redux";

import authReducer from "./auth";
import promptsReducer from "./prompts";
import blogReducer from "./blog";
import pricingReducer from "./pricing";
import notificationReducer from "./notification"
import toasterReducer from "./toaster"
import toolReducer from "./tools"
import projectReducer from "./project"
import headerReducer from "./header"

export default combineReducers({
    authReducer,
    promptsReducer,
    blogReducer,
    pricingReducer,
    notificationReducer,
    toasterReducer,
    toolReducer,
    projectReducer,
    headerReducer 
});
