import LoadingCover from "components/Loadingcover";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const PublicRoutes = ({ children }) => {
  const { loading, isAuth } = useSelector((state) => state.authReducer);
  const location = useLocation();

  if (loading) {
    return <LoadingCover/>;
  }

  if (isAuth) {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }

  return (
    <>
    {children}
    </>
  );
};

export default PublicRoutes;
