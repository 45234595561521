import * as actionTypes from "../constants";

const initialState = {
    creating: false,
    loading: false,
    error: null,
    prompts: []
};

const promptsReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case actionTypes.REQUEST_CREATE_PROMPT:
            return {
                ...state,
                creating: true,
            };

        case actionTypes.CREATE_PROMPT_SUCCESS:
            return {
                ...state,
                creating: false,
            };

        case actionTypes.CREATE_PROMPT_FAIL:
            return {
                ...state,
                creating: false,
                error: payload
            };

        case actionTypes.REQUEST_GET_PROMPTS:
            return {
                ...state,
                loading: true,
            };

        case actionTypes.GET_PROMPTS_SUCCESS:
            return {
                ...state,
                loading: false,
                prompts: payload
            };

        case actionTypes.GET_PROMPTS_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            };

        default:
            return state;
    }
};

export default promptsReducer;
