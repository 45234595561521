import React, { lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// components
import PositionedSnackbar from "../components/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

// Constant
import * as actionTypes from "redux/constants";

// data
import * as data from "../data";

// routes
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

// redux
import * as actions from "../redux/actions/auth";
import { Tools } from "redux/actions/tools";
import routes from "common/routes/routes";

// pages

const NotFound = lazy(() => import("./NotFound"));
const Home = lazy(() => import("./Landing/index"));
const Benifit = lazy(() => import("./Landing/Content/Benifit"));
const Login = lazy(() => import("./Auth/Login"));
const Register = lazy(() => import("./Auth/Register"));
const PositionedSidebar = lazy(() => import("components/Sidebar"));
const PhaseTwoHeader = lazy(() => import("components/Header/phase2"));
const Profile = lazy(() => import("./Profile"));
const Contactus = lazy(() => import("./ContactUs"));



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Website = () => {
  const { isAuth } = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Tools());
  }, []);

  useEffect(() => {
    dispatch(actions.authChecking());
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(actions.logout());
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: actionTypes.SET_TOASTER,
      payload: {
        active: false,
        message: "",
        type: "",
      },
    });
  };
  const { active, message, type } = useSelector(
    (state) => state.toasterReducer
  );

  return (
    <>
      <div className="flex w-screen overflow-hidden bg-mediumLightGray">
        <PositionedSidebar menus={data.header} isAuth={isAuth} />
        <div className="flex flex-col w-screen overflow-hidden">
          <PhaseTwoHeader
            menus={data.header}
            handleLogout={handleLogout}
            myProfile={"/profile"}
            isAuth={isAuth}
          />

          <Routes>
            <Route
              exact
              path="/"
              element={
                <PublicRoutes>
                  <Home />
                </PublicRoutes>
              }
            />
            <Route
              exact
              path="/home"
              element={
                <PublicRoutes>
                  <Home />
                </PublicRoutes>
              }
            />

            <Route
              exact
              path="/about"
              element={
                <PublicRoutes>
                  <Benifit />
                </PublicRoutes>
              }
            />

            <Route
              exact
              path="/login"
              element={
                <PublicRoutes>
                  <Login />
                </PublicRoutes>
              }
            />
            <Route
              exact
              path="/register"
              element={
                <PublicRoutes>
                  <Register />
                </PublicRoutes>
              }
            />
            <Route
              exact
              path="/contact-us"
              element={
                <PublicRoutes>
                  <Contactus />
                </PublicRoutes>
              }
            />
            <Route
              exact
              path="/home"
              element={
                <PublicRoutes>
                  <Home />
                </PublicRoutes>
              }
            />

            <Route
              exact
              path="/profile"
              element={
                <PrivateRoutes>
                  <Profile />
                </PrivateRoutes>
              }
            />

            {routes &&
              routes.map((ele, idx) => (
                <Route
                  exact
                  key={idx}
                  path={ele.path}
                  element={<PrivateRoutes>{ele.component}</PrivateRoutes>}
                />
              ))}

            <Route
              path="*"
              element={
                <PublicRoutes>
                  <NotFound />
                </PublicRoutes>
              }
            />
          </Routes>
        </div>
      </div>

      <PositionedSnackbar />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={active}
        autoHideDuration={4000}
        onClose={handleClose}
        message={message}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Website;
