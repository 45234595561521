import * as actionTypes from "../constants";

const initialState = {
  notification: {
    type: "",
    message: "",
    open: false,
    autoHideDuration: 2000,
  },
};

const notificationReducer = (state = initialState, action = {}) => {
  const { payload, type } = action;
  switch (type) {
    case actionTypes.SHOW_SNACKBAR_SUCCESS:
    case actionTypes.SHOW_SNACKBAR_FAIL:
      return {
        ...state,
        notification: payload,
      };

    case actionTypes.CLEAR_SNACKBAR_MESSAGE:
      return {
        ...state,
        notification: {
          type: "",
          message: "",
          open: false,
          autoHideDuration: 2000,
        },
      };

    default:
      return state;
  }
};

export default notificationReducer;
