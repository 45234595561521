import * as actionTypes from "../constants";

const initialState = {
  active: false,
  message : "",
  type: null,
};

const toastReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
 
    case actionTypes.SET_TOASTER:
      return payload;

    default:
      return state;
  }
};

export default toastReducer;
